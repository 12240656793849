<template>
  <el-cascader
    :options="departmentData"
    :props="props"
    @change="depChange"
    clearable
    collapse-tags
    v-model="depData"
    placeholder="选择部门"
    style="width: 250px"
  ></el-cascader>
</template>
<script>
export default {
  data() {
    return {
      visible: true,
      props: {
        multiple: true,
        checkStrictly: true,
        value: 'pid',
        label: 'name'
      },
      departmentData: [],
      depData: [],
      dept_ids: []
    }
  },
  mounted() {
    this.getDepartmentData()
  },
  methods: {
    show() {
      this.visible = true
    },
    //获取部门列表
    getDepartmentData() {
      this.$axios.post('/dept/lists').then(res => {
        if (res.data.code == 200) {
          this.departmentData = res.data.data
        }
      })
    },
    //选中部门
    depChange() {
      this.dept_ids = []
      this.depData.map(item => {
        this.dept_ids.push(item[item.length - 1])
        //item[item.length - 1]
      })
      this.$emit('setDeps', this.dept_ids)
    }
  }
}
</script>
<style lang="less" scoped>
.wrapper {
  /deep/ .el-cascader {
    width: 100%;
  }
  /deep/ .el-tag {
    margin-right: 5px;
  }
}
</style>